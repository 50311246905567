import { getRequest, postRequest, deleteRequest } from "app/axiosClient";
import { message } from "antd";
import {
  getPartsRecords,
  getNonStockItemRecords,
  getTargetEquipmentRecords,
  getVendorRecords,
} from "./PartsSlice";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { showNotification } from "../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getAllPartsRecordsApi = (input) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Parts/GetPartsList`, input)
      .then((res) => {
        dispatch(getPartsRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());

        dispatch(
          showNotification({
            show: true,
            messageId: "Parts.UnablePerformOperation",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Parts.UnablePerformOperation",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const addUpdatePartApi =
  (values, setPartEditMode, setClearFilter) => (dispatch) => {
    try {
      dispatch(showLoader());
      postRequest(`api/Parts/AddUpdateParts`, values)
        .then((res) => {
          var input = {
            appoinmentNbr: res.data,
            inventorysearch: null,
            descriptionsearch: null,
          };
          dispatch(getAllPartsRecordsApi(input));
          setPartEditMode(false);
          setClearFilter(false);
          if (values.id != null) {
            dispatch(
              showNotification({
                show: true,
                messageId: "Parts.Partsuccessfullyupdated",
                type: "success",
              })
            );
          } else {
            dispatch(
              showNotification({
                show: true,
                messageId: "Parts.PartSuccessfullyAddedAppointment",
                type: "success",
              })
            );
          }
        })
        .catch((e) => {
          dispatch(hideLoader());

          dispatch(
            showNotification({
              show: true,
              messageId: "Parts.UnablePerformOperation",
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(
        showNotification({
          show: true,
          messageId: "Parts.UnablePerformOperation",
          type: "error",
        })
      );
      dispatch(hideLoader());
    }
  };

export const deletePartApi = (payload, setClearFilter) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Parts/DeletePart`, payload)
      .then((res) => {
        var input = {
          appoinmentNbr: res.data,
          inventorysearch: null,
          descriptionsearch: null,
        };
        dispatch(getAllPartsRecordsApi(input));
        setClearFilter(false);
        dispatch(
          showNotification({
            show: true,
            messageId: "Parts.SuccessfullyDeletedPart",
            type: "success",
          })
        );
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Parts.UnableDeletePart",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Parts.UnableDeletePart",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const getNonStockItemsApi = (type) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Lookup/GetNonStockItemsList?ItemType=${type}`)
      .then((res) => {
        dispatch(getNonStockItemRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Parts.TryAgain",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Parts.TryAgain",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const getTargetEquipmentsApi = (customerId, location) => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(
      `api/Lookup/GetTargetEquipmentList?customerId=${customerId}&location=${location}`
    )
      .then((res) => {
        dispatch(getTargetEquipmentRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Parts.TryAgain",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Parts.TryAgain",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const getVendorListApi = () => (dispatch) => {
  try {
    dispatch(showLoader());
    getRequest(`api/Lookup/GetVendorList`)
      .then((res) => {
        dispatch(getVendorRecords(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Parts.TryAgain",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showNotification({
        show: true,
        messageId: "Parts.TryAgain",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

import { createSlice } from "@reduxjs/toolkit";
export const PartsSlice = createSlice({
  name: "Parts",
  initialState: {
    partsRecords: [],
    nonStockItemRecords: [],
    targetEquipmentRecords: [],
    vendorRecords: [],
  },
  reducers: {
    getPartsRecords: (state, action) => {
      state.partsRecords = action.payload;
    },
    getNonStockItemRecords: (state, action) => {
      state.nonStockItemRecords = action.payload;
    },
    getTargetEquipmentRecords: (state, action) => {
      state.targetEquipmentRecords = action.payload;
    },
    getVendorRecords: (state, action) => {
      state.vendorRecords = action.payload;
    },
  },
});
export const {
  getPartsRecords,
  getNonStockItemRecords,
  getTargetEquipmentRecords,
  getVendorRecords,
} = PartsSlice.actions;
export default PartsSlice.reducer;
